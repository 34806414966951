<template>
  <div class="slot-card" @click="selectedValue = !selectedValue">
    <div class="info">
      <div class="time">{{ lesson.type === 'test' ? 'Тест. ' : '' }}{{ lesson.name }}</div>
    </div>
    <div class="actions">
      <Checkbox v-model="selectedValue" not-clickable />
    </div>
  </div>
</template>

<script setup>
import Checkbox from '@/components/common/Checkbox'

const props = defineProps({
  selected: Boolean,
  lesson: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['update:selected'])

const selectedValue = computed({
  get: () => props.selected,
  set: value => emit('update:selected', value)
})
</script>

<style scoped lang="scss">
.slot-card {
  border: 1px solid var(--color-separator);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: .1s border-color;
  &:hover {
    border-color: var(--color-primary);
  }
  & .info {
    & .time {
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
    }
    & .count {
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--color-elements-secondary);
    }
  }
  & .actions {
    display: flex;
    align-items: center;
    & .coins {
      margin-right: 80px;
    }
  }
  &.unavailable {
    opacity: .5;
    pointer-events: none;
  }
}
</style>
