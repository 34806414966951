<template>
  <SidebarFluid
    title="Материалы для менти"
    @close="emit('close')"
  >
    <div :class="{ pending: pending || loading }">
      <div class="section-list">
        <div class="section-item" v-for="section in materials" :key="section.id">
          <div class="section-item__name">{{ section.name }}</div>
          <div class="section-item__list">
            <MentiLessonCard v-for="lesson in section.lessons" :key="lesson.id" :lesson="lesson" v-model:selected="lesson.selected" />
          </div>
        </div>
      </div>
      <PrimaryButton class="btn" :class="{ disabled: selected.length === 0 }" @click="submit">Сохранить</PrimaryButton>
      <div :style="{ opacity: +(Object.keys(errors).length > 0) }" class="error">Что-то пошло не так. Попробуйте еще раз.</div>
    </div>
  </SidebarFluid>
</template>

<script setup>
import SidebarFluid from '@/components/common/SidebarFluid'
import { getSections, shareMaterials } from '@/api/materials'
import MentiLessonCard from '@/components/menti/MentiLessonCard'
import PrimaryButton from '@/components/common/PrimaryButton'
import useForm from '~/composables/useForm'

const props = defineProps({
  menti: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['reload', 'close'])

const { data: materials, pending } = await useAsyncData(getSections, { transform: data => data.filter(m => m.lessons.length > 0).map(s => {
    return Object.assign({}, s, {
      lessons: s.lessons.map(l => Object.assign({}, l, {
        selected: props.menti.materials.map(m => m.lesson_id).includes(l.id)
      }))
    })
  }) })

const selected = computed(() => {
  const selection = []
  materials.value.forEach(m => selection.push(...m.lessons.filter(l => l.selected).map(l => l.id)))
  return selection
})
const { form, loading, errors, submit } = useForm(
  () => shareMaterials(props.menti.id, { lessons: selected.value }),
  () => {
    emit('reload')
    emit('close')
  }
)
</script>

<style scoped lang="scss">
.section-item {
  padding: 20px 0;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: 1px;
    background: var(--color-separator);
  }

  &__name {
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    color: var(--color-elements-secondary);
  }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
}

.btn {
  margin-top: 30px;
  width: 100%;
  display: flex;
}

.error {
  color: var(--color-error);
  margin-top: 30px;
}
</style>
