<template>
  <main>
    <div class="horiz-container">
      <div class="materials-heading">
        <FancyBreadcrumbs v-if="related" :links="[
          { name: `Мои ${user.is_mentor ? 'менти' : 'менторы'}`, to: { name: 'mentor-my' } },
          { name: `${related.first_name} ${related.last_name}`, to: { name: 'mentor-lessons', params: { id: related.id } } },
          { name: 'Материалы', to: { name: 'mentor-materials', params: { id: related.id } } },
        ]" />
        <h1 class="page-title">Материалы</h1>
      </div>
      <div class="course-view" v-if="lessons.length > 0 || user.is_mentor">
        <div class="sidebar">
          <button class="sidebar-toggle" :class="{ 'sidebar-toggle--active': sidebarOpened }"
                  @click="sidebarOpened = !sidebarOpened">
            Материалы
            <svg class="sidebar-toggle-icon" width="32" height="32" viewBox="0 0 32 32">
              <g transform="translate(8, 8)">
                <path id="upper" d="M 1 1 L 8 8 L 15 1" stroke="currentColor" stroke-width="2" fill="none"/>
                <path id="lower" d="M 1 15 L 8 8 L 15 15" stroke="currentColor" stroke-width="2" fill="none"/>
              </g>
            </svg>
          </button>
          <ul class="sidebar-list sidebar-list--root" @click="sidebarOpened = false">
            <li class="sidebar-item mb-5" v-if="user.is_mentor">
              <a class="sidebar-link sidebar-lesson-link" @click="shareSidebar = true">
                <span class="sidebar-lesson-name">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-tool" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                     <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                     <path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6 -6a6 6 0 0 1 -8 -8l3.5 3.5"></path>
                  </svg>
                  Управление
                </span>
              </a>
            </li>
            <li class="sidebar-item" v-for="lesson in lessons" :key="lesson.id">
              <NuxtLink class="sidebar-link sidebar-lesson-link" :to="{ name: 'mentor-materials-lesson', params: { id: related.id, lessonId: lesson.lesson_id } }">
                <span class="sidebar-lesson-name">{{ lesson.name }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div class="course-view-container">
          <NuxtPage />
        </div>
      </div>
      <h3 v-else-if="!pending" class="empty">Материалы отсутствуют</h3>
    </div>
  </main>
  <Teleport to="body">
    <transition name="sidebar-fluid">
      <MentiShareSidebar v-if="shareSidebar" :menti="related" @close="shareSidebar = false" @reload="refresh" />
    </transition>
  </Teleport>
</template>

<script setup>
import { getMentiProfile, getMyMentorProfile } from '@/api/mentoring'
import FancyBreadcrumbs from '@/components/menu/FancyBreadcrumbs'
import MentiShareSidebar from '@/components/menti/MentiShareSidebar'

useHead({
  title: 'Материалы'
})

const sidebarOpened = ref(false)
const route = useRoute()
const user = inject('user')

const { data: related, pending, refresh } = await useAsyncData(() => (user.value.is_mentor ? getMentiProfile : getMyMentorProfile)(route.params.id))
if (!related.value) throw createForbiddenError()

const lessons = computed(() => related.value ? related.value[user.value.is_mentor ? 'materials' : 'lessons'] : [])
provide('lessons', lessons)

const shareSidebar = ref(false)
</script>

<style lang="scss" scoped>
@media (min-width: 920px) {
  .course-view {
    display: grid;
    grid-template-columns: minmax(350px, 1fr) 4fr;
  }
}

.materials-heading {
  margin-bottom: 50px;
}

.sidebar {
  background: var(--color-dark);
  color: var(--color-text);
  border-right: 1px solid var(--color-separator);
}

.sidebar-toggle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  padding: 10px 10px 10px 20px;
  border: none;
  font: inherit;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: inherit;
  text-align: left;
}

.sidebar-toggle-icon #upper,
.sidebar-toggle-icon #lower {
  transition: 0.1s transform ease-out;
}

.sidebar-toggle-icon #upper {
  transform: translateY(12px);
}

.sidebar-toggle-icon #lower {
  transform: translateY(-12px);
}

.sidebar-toggle--active .sidebar-toggle-icon #upper,
.sidebar-toggle--active .sidebar-toggle-icon #lower {
  transform: none;
}

.sidebar-list {
  padding: 10px 0;

  &--root {
    display: none;
  }
}

.sidebar-toggle--active + .sidebar-list--root {
  display: block;
}

.sidebar-list .sidebar-list {
  margin-left: 20px;
  padding: 0;
}

@media (min-width: 920px) {
  .sidebar-toggle {
    display: none;
  }

  .sidebar-list {
    padding: 20px 0;

    &--root {
      display: block;
    }
  }
}

.sidebar-item {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar-link {
  display: flex;
  width: 100%;
  padding: 8px 12px 8px 20px;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  text-transform: inherit;
  text-align: left;
  cursor: pointer;

  > * + * {
    margin-left: 8px;
  }

  &.router-link-active,
  .router-link-active {
    color: var(--color-accent);
  }
}

.sidebar-lesson-link {
  padding-top: 6px;
  padding-bottom: 6px;
}

.sidebar-lesson-name {
  font-size: .9em;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}

.course-view-container {
  padding: 20px;
  color: var(--color-text);
  line-height: 1.5;
}

@media (min-width: 920px) {
  .course-view-container {
    padding: 20px 60px;
  }

  .sidebar-link {
    padding: 10px 16px 10px 30px;
  }

  .sidebar-lesson-link {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.lesson-seen-icon {
  flex-shrink: 0;
}

.access-forbidden-modal {
  max-width: 400px;

  &__button {
    margin-top: 24px;
    font-family: 'PF Din Text Cond Pro', sans-serif;
  }
}
</style>
